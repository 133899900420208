import React from 'react'
import { navigate } from 'gatsby'
import RequireAuth from '../components/RequireAuth'
import Layout from 'components/Layout'

/**
 * local test
 * http://localhost:1891/install?code=dc7e570af0b9419b7e2d0957b5b185ff&hmac=972b2c9bd5f0065f0244767545a0aef3f5b4153ffaf1037b89b22a387dff2766&host=bXVyYWthbWljbGluaWMubXlzaG9waWZ5LmNvbS9hZG1pbg&shop=murakamiclinic.myshopify.com&timestamp=1674793999
 */
const InstallPage = () => {

  const [ loading, setLoading ] = React.useState(true)
  const [ result, setResult ] = React.useState('LOADING...')

  React.useEffect(() => {
    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    const _shop = urlParams.get('shop')
    if (!_shop) {
      // todo something about no shop param
      setResult('Something went wrong with your installation. Please go back and try again')
      setLoading(false)
    } else if (urlParams.get('session')) {
      // user is already authenticated
      navigate('/app')
    } else {
      // useAuth hook will handle authentication from here
    }
  }, [])

  return (
    <Layout>
      <RequireAuth>
        Installation successful! Redirecting...
        { loading ? 'loading' : ''}
        { result || ''}
      </RequireAuth>
    </Layout>
  )
}

export default InstallPage
