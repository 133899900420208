import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Card, DisplayText, Frame, FooterHelp, Link, Loading, Page, TextContainer, Heading, TextStyle } from '@shopify/polaris'
import useAuth from '../lib/hooks/useAuth'
import pkg from '../../package.json'
import SETTINGS from '@sh/settings'
import * as log from '../lib/method/log'

const InstallFrame = ({ txtMsg, loading }) => (
  <Frame>
    <div className='mt-10'>
      <Page>
        <Card>
          <Card.Section flush>
            <div className='text-right py-3 pr-10 shadow-md border'>
              <StaticImage src='../images/icon.png'
                alt='1F8!'
                placeholder='blurred'
                width={80}
                height={80}
              />
            </div>
            <div className='lg:flex items-center shadow-xl'>
              <div className='lg:w-1/2 py-16 px-16'>
                <StaticImage src='../images/growth.svg'
                  alt='Installing!'
                  placeholder='blurred'
                  width={400}
                  height={400}
                />
              </div>
              <div className='lg:w-1/2 p-2 text-center'>
                <TextContainer>
                  <Heading>{`Installing ${SETTINGS.app.name}`}</Heading>
                  <p>
                    {SETTINGS.app.name} is the easiest way to sell your products in person. Available
                    for iPad, iPhone, and Android.
                  </p>
                </TextContainer>
                <div className='bg-gray-100 pt-8 pb-10 m-10 rounded-lg shadow-lg w-1/2 mx-auto'>
                  <TextContainer>
                    <TextStyle variation='strong'>Installation processing</TextStyle>
                    <p className='loader'></p>
                  </TextContainer>
                </div>
              </div>
            </div>
          </Card.Section>
        </Card>
        <div className='flex items-center justify-center' style={{ paddingTop: '28%' }}>
          <Card sectioned>
            {loading && <Loading />}
            <DisplayText size='large'>
              {txtMsg}
            </DisplayText>
            {!loading && txtMsg && (
              <div className='mt-4'>
                <DisplayText>
                  Try going back to your store app page and installing again.
                </DisplayText>
              </div>
            )}
          </Card>
        </div>
        <FooterHelp>
          Learn more about{' '}
          <Link url='/'>
            {SETTINGS.app.name} v{pkg.version}
          </Link>
        </FooterHelp>
      </Page>
    </div>
  </Frame>
)

const RequireAuth = ({ children }) => {

  const { loading, success, msg } = useAuth()

  return (
    <>
      {loading
        ? null
        : !success
        ? <InstallFrame loading={false} txtMsg={msg} />
        : <Frame>{children}</Frame>}
    </>
  )
}

export default RequireAuth
