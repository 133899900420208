import { useState, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import S from '@sh/settings'
import { Store } from '@sh/models/classes'
import * as api from 'lib/apis/graphql'
import * as atoms from 'lib/atoms'
import * as log from 'lib/method/log'

const DEBUG = false
const debug = (...args) => {
  if (!DEBUG) return
  log.info(...args)
}

/**
 * Local test endpoints
 * localhost:1891/install?shop=test.myshopify.com
 */

// configure shopify
const {
  key: API_KEY,
  id:  APP_ID,
  callback: CALLBACK_URL,
 } = S.app
const SCOPES = S.scopes

// required params
interface AuthProps {
  ignorePath: boolean
  code?: string
  shopOrigin?: string
  queryParams?: string
  session?: string
}

const useAuth = () => {

  const [ loading, setLoading ] = useState(true)
  const [ success, setSuccess ] = useState(false)
  const [ msg, setMsg ] = useState('')

  const setStore = useSetRecoilState(atoms.store)
  const setInstalled = useSetRecoilState(atoms.appInstalled)

  const setQueryParams = ():AuthProps => {
    const path = window.location.pathname

    // ignore public paths which dont require auth
    if (path==='/' || path.startsWith('/demo') || path.startsWith('/notifications')) {
      return { ignorePath: true }
    }

    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    const shopOrigin = urlParams.get('shop')
    const host = urlParams.get('host')

    setStore(_store => {
      const store = new Store(_store)
      if (shopOrigin) store.setShopifyOrigin(shopOrigin)
      if (host) store.host = host
      return store
    })

    const code = urlParams.get('code')
    const session = urlParams.get('session')
    return {
      ignorePath: false,
      code,
      shopOrigin,
      queryParams,
      session,
    }
  }

  // first step
  const redirectUserToInstallPage = (shopOrigin:string) => {
    const url = [
      `https://${shopOrigin}/admin/oauth/authorize?client_id=${API_KEY}`,
      `scope=${SCOPES.join(',')}`,
      `redirect_uri=${CALLBACK_URL}`,
    ].join('&')
    window.location.assign(url)
  }
  /**
   * second step
   * TODO: remove manual construction fo admin url?
   * https://shopify.dev/apps/tools/app-bridge/getting-started#whats-next
   */
  const redirectUserToApp = (shopOrigin:string) => {
    const url = `https://${shopOrigin}/admin/apps/${APP_ID}`
    window.location.assign(url)
  }

  // user has already installed the app
  const createAppInstance = () => {
    setInstalled(true)
    setSuccess(true)
  }

  // install page has a code to exchange for a token
  const handleAuthorization = (queryParams:string, shopOrigin:string) => {
    api.store.authorize(queryParams.substring(1))
      .then((result:string) => {
        debug('handle auth response', result)
        if (result==='SUCCESS' || result==='EXISTS') {
          setSuccess(true)
          redirectUserToApp(shopOrigin)
        } else {
          log.error('auth failed', result)
        }
      })
      .catch(err => {
        log.error('Authorization Error', err)
        setMsg('Authorization Error')
        setLoading(false)
      })
  }

  const handleFailure = () => {
    setMsg('500: Invalid Page')
    setLoading(false)
  }

  useEffect(() => {

    const {
      ignorePath,
      code,
      shopOrigin,
      queryParams,
      session,
    } = setQueryParams()

    debug({
      ignorePath,
      code,
      shopOrigin,
      queryParams,
      session,
    })

    if (ignorePath) {
      setLoading(false)
      setSuccess(true)
      return
    }

    // final step) install completed
    if (session && shopOrigin) {
      debug('final step) install completed')
      createAppInstance()
      return
    }

    // 1) unauthorized user
    if (shopOrigin && !code) {
      debug('1) unauthorized user')
      redirectUserToInstallPage(shopOrigin)
      return
    }

    // 2) validate code/session with backend
    if (shopOrigin && code) {
      debug('2) validate code/session with backend')
      handleAuthorization(queryParams, shopOrigin)
      return
    }

    // something unexpected happened
    debug('unexpected failure. logic fellthrough')
    handleFailure()

  // only run once on load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading,
    success,
    msg,
  }
}

export default useAuth
